//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    popData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    isApply: {
      type: Boolean,
      default: false,
    },
    teamValue: {
      type: Number,
      default: null,
    },
  },
  components: {
    XModal: () => import("@/components/XModal"),
    // askLeave: () => import("@/views/leaveApplication/common"),
  },
  data() {
    return {
      ruleForm: {
        AnomalyTime: null,
        AnomalyType: [1, 2],
        ClockInTime: null, //上班时间
        ClockInStatus: null, //上班状态
        ClockOutTime: null, //下班时间
        ClockOutStatus: null, //下班状态
        Reason: null,
      },
      rules: {
        Reason: [
          {
            required: true,
            message: "请输入申诉原因",
            trigger: "blur",
          },
        ],
      },
      pickOption: this.pickTime(),
    };
  },
  methods: {
    filterOption(item) {
      if (item.value == -1 || item.value == 3 || item.value == 5) {
        return true;
      } else {
        return false;
      }
    },
    pickTime() {
      let _this = this;
      return {
        disabledDate(time) {
          let nextDay = new Date(_this.popData.Date).setDate(
            new Date(_this.ruleForm.Date).getDate() + 1
          );
          return (
            time.getTime() < new Date(_this.popData.Date).getTime() ||
            time.getTime() > new Date(nextDay).getTime()
          );
        },
      };
    },
    onConfirm() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.$refs.myXModal.loadBtn(true);
          this.ruleForm.teamId = this.teamValue;
          this.ruleForm.AnomalyTime = this.popData.Date;
          this.$http
            .post(
              "/Attendance/AnomalyAppeals/SaveAnomalyAppeal.ashx",
              this.ruleForm
            )
            .then((resp) => {
              if (resp.res == 0) {
                this.$notify({
                  message: resp.msg,
                  type: "success",
                });
                this.$modal.hide("appeal");
                this.$emit("appealSubmit");
              }
            })
            .finally(() => this.$refs.myXModal.loadBtn(false));
        }
      });
    },
    closed() {
      this.ruleForm = {
        AnomalyTime: null,
        AnomalyType: [1, 2],
        ClockInTime: null, //上班时间
        ClockInStatus: null, //上班状态
        ClockOutTime: null, //下班时间
        ClockOutStatus: null, //下班状态
        Reason: null,
      };
    },
    opened() {},
    success() {
      this.$modal.hide("askLeave");
    },
  },
};
